import React from "react"
import Layout from "../components/Layout"

const Canceled = () => {
  return (
    <Layout titlePage="Canceled">
      <div className="flex-cancel">
        <h1>Poles on Earth</h1>
        <p>The purchase has been canceled.</p>
      </div>
    </Layout>
  )
}

export default Canceled
